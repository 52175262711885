export const CURRENCIES = {
  USD: { cents: 100, symbol: '$' },
  AUD: { cents: 100, symbol: `A$` },
  EUR: { cents: 100, symbol: `\u20AC` },
  GBP: { cents: 100, symbol: '\u00A3' },
  CAD: { cents: 100, symbol: '$' },
  BRL: { cents: 100, symbol: 'R$' },
  IDR: { cents: 100, symbol: 'Rp' },
  MXN: { cents: 100, symbol: '\u8369' },
  INR: { cents: 100, symbol: '\u20B9' },
  ZAR: { cents: 100, symbol: 'R' },
  MYR: { cents: 100, symbol: 'RM' },
  MA: { cents: 100, symbol: 'MAD' }
}

export type Currency = keyof typeof CURRENCIES

export function formatCurrency(amount?: number | null, currency?: Currency): string {
  if (!amount || !currency) {
    return ''
  }
  const currencySymbol = CURRENCIES[currency].symbol

  return `${currencySymbol}${amount.toFixed(2)}`
}

export function toCents(dollars: number | string | null, currency: Currency): number | null {
  if (!dollars) {
    return null
  }
  return Number(dollars) * CURRENCIES[currency].cents
}

export function toDollars(
  cents: number | string | undefined | null,
  currency: Currency
): number | null {
  if (!cents) {
    return null
  }
  return Number(cents) / CURRENCIES[currency].cents
}
