import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import styled from 'styled-components'

export const GridText = styled(Text)<{ gridArea: string }>`
  grid-area: ${({ gridArea }: { gridArea: string }) => gridArea};
`
export const Strikethrough = styled(Text)`
  text-decoration: line-through;
`

export const CopyWrapper = styled(Box)`
  white-space: nowrap;
`
export const ProductInfo = styled(Box)<{ hasPromo: boolean; hasSale: boolean }>`
  display: grid;
  max-width: 200px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: ${({ hasPromo }) => (hasPromo ? '0px' : '10px')};
  grid-template-areas:
    'title'
    'description'
    'price';
  grid-row-gap: 2px;
  grid-template-rows: auto 50px 25px;
`
