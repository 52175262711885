import { FC } from 'react'
import { FlowpageProps } from '../types/FlowpageProps'
import { NoStyleLink, ProductCard } from './subcomponents'
import { getLinkCardStyles } from '../../../components/flowpage/FlowpageLinkCard/utils/getLinkCardStyles'
import { getValidatedActionDataOrThrow } from '../typeUtils'
import { removeNull } from '@dtx-company/true-common/src/utils/objects'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../../components/profile/Customize/hooks'
import { useGetLinkUrlWithParams } from '../../../utils/hooks'

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview,
  editLinkMode,
  isLockedTemplateLink
}: FlowpageProps) => {
  const curIdx = 0
  const actionData = getValidatedActionDataOrThrow<'promotedItem'>(link?.actionData, 'items')
  const item = actionData.items[curIdx]
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: Boolean(editLinkMode)
  })
  const { theme } = useFlowpageTheme()

  const linkUrlWithUtmParams = useGetLinkUrlWithParams(item.link)
  const linkStyles = getLinkCardStyles({
    linkTheme: link?.linkTheme,
    theme
  })
  return (
    <div id={link.id} style={{ width: '100%' }}>
      {preview ? (
        <ProductCard
          {...item}
          linkStyles={linkStyles}
          preview={preview}
          image={removeNull(link.thumbNailImgUrl)}
          isLockedTemplateLink={isLockedTemplateLink}
        />
      ) : (
        <NoStyleLink
          id={`hp-flowpage-link_card-${link.id}`}
          href={linkUrlWithUtmParams}
          target={'_blank'}
          rel="noopener"
          data-link_id={link.id}
          data-widget="promoted-item"
          data-testid="promoted-item-link"
          onClick={() => trackLinkClick({ link_id: link.id }, { useHeap: true })}
        >
          <ProductCard
            {...item}
            isLockedTemplateLink={isLockedTemplateLink}
            linkStyles={linkStyles}
            preview={preview}
            image={removeNull(link.thumbNailImgUrl)}
            editLinkMode={editLinkMode}
          />
        </NoStyleLink>
      )}
    </div>
  )
}
