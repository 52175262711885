import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { EditLinkCardEndAdornment } from '../EditLinkUtils'
import { FC } from 'react'
import { GridText, ProductInfo, Strikethrough } from './styles'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { PromotedItemProps } from './types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { formatCurrency, toDollars } from '../../../utils/currency'
import styled from 'styled-components'

export const ProductCard: FC<
  PromotedItemProps & {
    image?: string
    preview?: boolean
    editLinkMode?: boolean
    linkStyles?: Omit<BoxProps, 'onClick' | 'children'>
    isLockedTemplateLink?: boolean
  }
> = ({
  title,
  description,
  price,
  salePrice,
  promoCode,
  linkStyles,
  image,
  preview,
  currency,
  isLockedTemplateLink,
  editLinkMode = false
}) => {
  const markdown = salePrice && price ? Math.round(100 - (salePrice / price) * 100) : 0
  const dollarPrice = toDollars(price, currency)
  const dollarSalePrice = toDollars(salePrice, currency)
  return (
    <Box
      bg="primary.white"
      boxShadow="float"
      width="100%"
      height="auto"
      borderRadius="12px"
      overflow="hidden"
      {...linkStyles}
    >
      <Box flexDirection="column" width="100%">
        <Box>
          <Image
            padding={image ? 0 : '30px'}
            maxWidth={preview ? 100 : 120}
            bg={!image ? 'secondary.backgroundDark' : null}
            objectFit="cover"
            borderRadius={promoCode ? '12px 0px 0px 0px' : '12px 0px 0px 12px'}
            src={image || icons.product}
          />
          <ProductInfo
            hasPromo={Boolean(promoCode)}
            hasSale={Boolean(salePrice)}
            ml="8px"
            flexDirection="column"
            flexShrink={0}
          >
            <GridText
              gridArea="title"
              minHeight="16px"
              variant="body/small"
              fontWeight="bold"
              fontSize={'inherit'}
              color="inherit"
              lineHeight="inherit"
            >
              {title}
            </GridText>
            <GridText gridArea="description" minHeight="40px" color="inherit">
              {description}
            </GridText>
            <Box gridArea="price">
              {dollarSalePrice ? (
                <>
                  <Text mr="12px" fontWeight="bold" color="inherit">
                    {formatCurrency(dollarSalePrice, currency)}
                  </Text>
                  <Strikethrough color="inherit" mr="12px">
                    {formatCurrency(dollarPrice, currency)}
                  </Strikethrough>
                  <Text color="inherit">{markdown}%</Text>
                </>
              ) : (
                <Text color="inherit">{formatCurrency(dollarPrice, currency)}</Text>
              )}
            </Box>
          </ProductInfo>
          {editLinkMode && (
            <EditLinkCardEndAdornment
              isLockedTemplateLink={isLockedTemplateLink}
              wrapperWidth="5%"
              margin="0 10px 0 auto"
              iconWidth="10px"
              iconMargin="auto"
            />
          )}
        </Box>
        {promoCode && (
          <Box
            bg="primary.white"
            padding="4px 0px"
            width="100%"
            alignItems="center"
            justifyContent="center"
            borderRadius="0px 0px 12px 12px"
          >
            <Text fontSize="13px" color="masterWeb.blue2" as="div" textAlign="center">
              Promo Code:&nbsp;
              <b>{promoCode}</b>
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export const NoStyleLink = styled.a`
  color: inherit;
  text-decoration: inherit;
  width: 100%;
  img {
    object-fit: cover;
  }
`
